import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import Slider from "../components/Sliderhome";
import Featurehome from "../components/Featurehome";
import Abouthome from "../components/Abouthome";
import Carouselfeature from "../components/Carouselfeature";
import Carouselclient from "../components/Carouselclient";
import Carouselclientb from "../components/Carouselclientb";
import Carouselprojects from "../components/Carouselprojects";
import Carouselteam from "../components/Carouselteam";
import Accomplish from "../components/Accomplish";
import Carouselnews from "../components/Carouselnews";
import Footer from "../components/Footer";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";
import Pricing from "./Pricing";

const Home = ({ history }) => {
  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />

      <Reveal effect="fadeIn">
        <section className="jumbotron jumbomain">
          <Slider />
        </section>
      </Reveal>

      {/* <Featurehome /> */}

      <Reveal effect="fadeInUp">
        <section className="container-fluid" id="how_it_works">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">It's simpler than you think</div>
            </div>
            <div className="col-12">
              {/* <Carouselfeature /> */}
              <div className="row mt--30">
                <div className="col-lg-1 col-md-1 col-sm-6 col-12 mt--30 single-work"></div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>01</span>
                    </div>
                    <div className="content">
                      <h4>Sign in &amp; Create New Project</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="content">
                      <h4>Fill the form and upload file</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>03</span>
                    </div>
                    <div className="content">
                      <h4>Receive a call from designer</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>04</span>
                    </div>
                    <div className="content">
                      <h4>Receive sample output</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>05</span>
                    </div>
                    <div className="content">
                      <h4>Download the final output</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      {/* <Reveal effect="fadeInUp">
        <section className="container-fluid py-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <Carouselclient />
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className="container-fluid">
          <div className="row m-2-hor">
            <div className="col-12">
              <Carouselclientb />
            </div>
          </div>
        </section>
      </Reveal> */}

      <Reveal effect="fadeInUp">
        <section className="container-fluid pt-0" id="offering">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">We Specialize in Visualization for</div>
            </div>
            <div className="col-12">
              <Carouselnews />
            </div>
          </div>
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <Abouthome />
      </Reveal>

      {/* <Reveal effect="fadeInUp">
        <section className="container-fluid py-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">Our Projects</div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 p-0">
              <Carouselprojects />
            </div>
          </div>
        </section>
      </Reveal> */}
      <div id="pricing">
        <Reveal effect="fadeInUp">
          <section className="container-fluid">
            <div className="row m-2-hor">
              <div className="col-12">
                <div className="heading">
                  Great Design at an affordable pricing
                </div>
              </div>
              {/* <div className='col-12'>
              <Carouselteam/>
            </div> */}
              <div className="col-12">
                <div className="card-deck mb-3 text-center">
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h5 className="my-0 font-weight-normal">
                        3D Images Video with complete designing + AutoCAD
                        Drawings
                      </h5>
                    </div>
                    <div className="card-body custom_card_body_li_margin">
                      {/* <h1 className="card-title pricing-card-title">
                $0 <small className="text-muted">/ mo</small>
              </h1> */}
                      {/* <img
                src="https://via.placeholder.com/150"
                className="img-fluid"
              /> */}
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>1 room - &#x20B9;. 3000</li>
                        <li>1 BHK - &#x20B9; 8000</li>
                        <li>2 BHK - &#x20B9; 9000</li>
                        <li>3 BHK - &#x20B9; 11000</li>
                        <li>Bungalow - &#x20B9; 15000</li>
                        <li>500sqft. Office - &#x20B9; 7000</li>
                        <li>1000sqft. Office - &#x20B9; 10000</li>
                        <li>1500sqft. Office - &#x20B9; 13000</li>
                      </ul>
                      {/* <button
                type="button"
                className="btn btn-lg btn-block btn-outline-primary"
              >
                Buy Now
              </button> */}
                    </div>
                  </div>
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h5 className="my-0 font-weight-normal">
                        Images + Video for 3D Model Provided
                      </h5>
                    </div>
                    <div className="card-body custom_card_body_li_margin">
                      {/* <h1 className="card-title pricing-card-title">
                $15 <small className="text-muted">/ mo</small>
              </h1> */}
                      {/* <img
                src="https://via.placeholder.com/150"
                className="img-fluid"
              /> */}
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>1 BHK - &#x20B9; 3000</li>
                        <li>2 BHK - &#x20B9; 4000</li>
                        <li>3 BHK - &#x20B9; 5500</li>
                        <li>Bungalow - &#x20B9; 7000</li>
                        <li>500sqft. Office - &#x20B9; 3000</li>
                        <li>1000sqft. Office - &#x20B9; 4000</li>
                        <li>1500sqft. Office - &#x20B9; 5000</li>
                      </ul>
                      {/* <button
                type="button"
                className="btn btn-lg btn-block btn-primary"
                style={{ marginTop: "5.2rem" }}
              >
                Buy Now
              </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Reveal>
      </div>

      {/* <Reveal effect="fadeInUp">
        <Accomplish />
      </Reveal> */}

      {/* <Reveal effect="fadeInUp">
        <section className="container-fluid pt-0">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">Our Services</div>
            </div>
            <div className="col-12">
              <Carouselnews />
            </div>
          </div>
        </section>
      </Reveal> */}

      <Footer />
    </div>
  );
};

export default withRouter(Home);

import React, { Component, Fragment } from "react";
import Reveal from "react-reveal/Reveal";
import Footer from "../components/Footer";
import { Efect, Efect1, Efect2 } from "../styles/effect.styles";
export class Pricing extends Component {
  render() {
    return (
      <Fragment>
        <Efect />
        <Efect1 />
        <Efect2 />

        {/* <div className="jumbotron head"/> */}

        <Reveal effect="fadeIn">
          <section className="jumbotron imgtop">
            <img src="./img/imgabout.jpg" className="img-fluid" alt="#" />
          </section>
        </Reveal>
        <Reveal effect="fadeInUp">
          <section className="container-fluid">
            <div className="row m-2-hor">
              <div className="col-12">
                <div className="heading">
                  Great Design at an affordable pricing
                </div>
              </div>
              {/* <div className='col-12'>
              <Carouselteam/>
            </div> */}
              <div className="col-12">
                <div className="card-deck mb-3 text-center">
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h5 className="my-0 font-weight-normal">
                        3D Images Video with complete designing + AutoCAD
                        Drawings
                      </h5>
                    </div>
                    <div className="card-body custom_card_body_li_margin">
                      {/* <h1 className="card-title pricing-card-title">
                $0 <small className="text-muted">/ mo</small>
              </h1> */}
                      {/* <img
                src="https://via.placeholder.com/150"
                className="img-fluid"
              /> */}
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>1 room - &#x20B9;. 3000</li>
                        <li>1 BHK - &#x20B9; 8000</li>
                        <li>2 BHK - &#x20B9; 9000</li>
                        <li>3 BHK - &#x20B9; 11000</li>
                        <li>Bungalow - &#x20B9; 15000</li>
                        <li>500sqft. Office - &#x20B9; 7000</li>
                        <li>1000sqft. Office - &#x20B9; 10000</li>
                        <li>1500sqft. Office - &#x20B9; 13000</li>
                      </ul>
                      {/* <button
                type="button"
                className="btn btn-lg btn-block btn-outline-primary"
              >
                Buy Now
              </button> */}
                    </div>
                  </div>
                  <div className="card mb-4 box-shadow">
                    <div className="card-header">
                      <h5 className="my-0 font-weight-normal">
                        Images + Video for 3D Model Provided
                      </h5>
                    </div>
                    <div className="card-body custom_card_body_li_margin">
                      {/* <h1 className="card-title pricing-card-title">
                $15 <small className="text-muted">/ mo</small>
              </h1> */}
                      {/* <img
                src="https://via.placeholder.com/150"
                className="img-fluid"
              /> */}
                      <ul className="list-unstyled mt-3 mb-4">
                        <li>1 BHK - &#x20B9; 3000</li>
                        <li>2 BHK - &#x20B9; 4000</li>
                        <li>3 BHK - &#x20B9; 5500</li>
                        <li>Bungalow - &#x20B9; 7000</li>
                        <li>500sqft. Office - &#x20B9; 3000</li>
                        <li>1000sqft. Office - &#x20B9; 4000</li>
                        <li>1500sqft. Office - &#x20B9; 5000</li>
                      </ul>
                      {/* <button
                type="button"
                className="btn btn-lg btn-block btn-primary"
                style={{ marginTop: "5.2rem" }}
              >
                Buy Now
              </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Reveal>
        <Footer />
      </Fragment>
    );
  }
}

export default Pricing;

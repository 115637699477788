import React, { Component } from "react";
import { Link } from "react-router-dom";
export class Whatsapp extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <div id="mybutton">
            <button class="feedback">
              <a
                href={
                  "https://api.whatsapp.com/send?phone=+919921948837&text=Hi%20we%20want%20to%20Connect"
                }
                target="_blank"
              >
                <i class="lni lni-whatsapp"></i>
              </a>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Whatsapp;

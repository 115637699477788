import React from "react";
import Slider from "react-animated-slider";
import "react-animated-slider/build/vertical.css";

const content = [
  {
    title:
      "We Provide 3D visualization services to Interior Designers and Architects",
    // description:
    //   "With precision",
    button: "Book Design Consultation",
    link: "/#",
    image: "./img/banner1.jpeg",
  },
  {
    title:
      "We Provide 3D visualization services to Interior Designers and Architects",
    // description:
    //   "With precision",
    button: "Book Design Consultation",
    link: "/#",
    image: "./img/banner2.jpeg",
  },
  {
    title:
      "We Provide 3D visualization services to Interior Designers and Architects",
    // description:
    //   "With precision",
    button: "Book Design Consultation",
    link: "/#",
    image: "./img/banner3.jpeg",
  },
];

export default () => (
  <Slider className="slider-wrapper" autoplay={6000}>
    {content.map((item, index) => (
      <div
        key={index}
        className="slider-content"
        style={{
          background: `linear-gradient(black, rgba(0,0,0,0.5)17%), url('${item.image}') no-repeat center center`,
        }}
      >
        <div className="inner">
          <h1>{item.title}</h1>
          <p>{item.description}</p>
          <button onClick={() => window.open(item.link, "_self")}>
            <span className="shine"></span>
            <span>{item.button}</span>
          </button>
        </div>
      </div>
    ))}
  </Slider>
);

import React from "react";

export default () => (
  <section className="container-fluid">
    <div className="row m-2-hor">
      {/* <div className='col-md-6 pt-5'>
              <div className='col-home'>
                <div className='thumb'>
                  <img
                      src="./img/home.jpg"
                      className="img-fluid"
                      alt="#"
                    />
                </div>
              </div>
            </div> */}

      <div className="col-md-12">
        <div className="">
          <div className="col-home mt-md-0 mt-5">
            <div className="heading">We do offer services right from</div>
            <div className="content">
              We believe that interior design is more than great functionality
              and beautiful aesthetics. We aim to make your home interiors a
              reflection of your personality. Your home should be something that
              you and your family take pride in and love to spend time in.
            </div>
            <ul className="list-home">
              <li>Taking measurements on site, making AutoCAD Plan</li>
              <li>
                Designing 3D models in Google SketchUp as per required design
                style
              </li>
              <li>
                Customized High Quality rendered images with various softwares
                as per need like Twin Motion, Lumion, 3DS Max
              </li>
              <li>Video Walkthroughs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);

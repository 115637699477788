import React from "react";
import { withRouter } from "react-router-dom";
import Reveal from "react-reveal/Reveal";

import Abouthome from "../components/Abouthome";
import Carouselclient from "../components/Carouselclient";
import Carouselclientb from "../components/Carouselclientb";
import Carouselteam from "../components/Carouselteam";
import Accomplish from "../components/Accomplish";
import Carouselprojects from "../components/Carouselprojects";
import Footer from "../components/Footer";
import Carouselnews from "../components/Carouselnews";

import { Efect, Efect1, Efect2 } from "../styles/effect.styles";

const How = ({ history }) => {
  return (
    <div>
      <Efect />
      <Efect1 />
      <Efect2 />

      {/* <div className="jumbotron head"/> */}

      <Reveal effect="fadeIn">
        <section className="jumbotron imgtop">
          <img src="./img/imgabout.jpg" className="img-fluid" alt="#" />
        </section>
      </Reveal>

      <Reveal effect="fadeInUp">
        <section className="container-fluid">
          <div className="row m-2-hor">
            <div className="col-12">
              <div className="heading">It's simpler than you think</div>
            </div>
            <div className="col-12">
              {/* <Carouselfeature /> */}
              <div className="row mt--30">
                <div className="col-lg-1 col-md-1 col-sm-6 col-12 mt--30 single-work"></div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>01</span>
                    </div>
                    <div className="content">
                      <h4>Sign in &amp; Create New Project</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>02</span>
                    </div>
                    <div className="content">
                      <h4>Fill the form and upload file</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>03</span>
                    </div>
                    <div className="content">
                      <h4>Receive a call from designer</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>04</span>
                    </div>
                    <div className="content">
                      <h4>Receive sample output</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-12 mt--30 single-work">
                  <div className="ht-process-2">
                    <div className="number">
                      <span>05</span>
                    </div>
                    <div className="content">
                      <h4>Download the final output</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Reveal>

      <Footer />
    </div>
  );
};

export default withRouter(How);

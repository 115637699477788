import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  Body,
  Page,
  LinkTag,
  SocialContainer,
  Overlaybg,
} from "../styles/Navigation.styles";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import NavButton from "./NavButton";
import $ from "jquery";

const NavigationMenu = ({ history, hasBackground, setBackground }) => {
  // const [isOn, setState] = useState(false);
  // const [shouldAnimate, setShouldAnimate] = useState(false);
  // const [linking, setLink] = useState("");

  // useEffect(() => {
  //   !!linking &&
  //     setTimeout(() => {
  //       switch (linking) {
  //         case "home":
  //           history.push("/");
  //           setState(false);
  //           setLink("");
  //           break;
  //         case "about":
  //           history.push("/about");
  //           setState(false);
  //           setLink("");
  //           break;
  //         case "work":
  //           history.push("/work");
  //           setState(false);
  //           setLink("");
  //           break;
  //         case "news":
  //           history.push("/news");
  //           setState(false);
  //           setLink("");
  //           break;
  //         case "contact":
  //           history.push("/contact");
  //           setState(false);
  //           setLink("");
  //           break;
  //         default:
  //           setLink("");
  //       }
  //       setBackground(false);
  //       window.scrollTo(0, 0);
  //     }, 0);
  // }, [linking, history, setBackground]);

  // useEffect(() => {
  //   shouldAnimate &&
  //     !isOn &&
  //     setTimeout(() => {
  //       setShouldAnimate(false);
  //     }, 0);
  // }, [shouldAnimate, isOn]);

  // const closeHandler = () => {
  //   setShouldAnimate(true);
  //   setState(!isOn);
  // };

  // const setLinkHandler = (text) => {
  //   setShouldAnimate(true);
  //   setLink(text);
  // };

  // useEffect(() => {
  //   $(function () {
  //     $(window).scroll(function () {
  //       if ($(this).scrollTop() > 1000) {
  //         $(".navbar-brand img").attr("src", "../components/assets/logo.png");
  //       }
  //       if ($(this).scrollTop() < 1000) {
  //         $(".navbar-brand img").attr(
  //           "src",
  //           "../components/assets/logo_black.png"
  //         );
  //       }
  //     });
  //   });
  // }, []);

  return (
    // <header>
    //   <div id="header"></div>
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top navbar-default navbar-fixed-top custom_navbar_sm_bg_black">
      <Link to="/">
        <h1>
          <a className="navbar-brand custom_navbar_mr" href="#">
            <img
              src={require("../components/assets/logo_black.png")}
              className="img-fluid"
              alt="logo"
              style={{ width: "15%" }}
            />
          </a>
        </h1>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto" style={{ marginTop: "-1rem" }}>
          {/* <Link to="/How_it_works"> */}
          <li className="nav-item">
            <a className="nav-link" href="#how_it_works">
              How It Work
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/About"> */}{" "}
          <li className="nav-item">
            <a className="nav-link" href="#offering">
              Offering
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/pricing"> */}{" "}
          <li className="nav-item">
            <a className="nav-link" href="#pricing">
              Pricing
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/pricing">
            {" "}
            <li className="nav-item">
              <a className="nav-link" href="#">
                Pricing
              </a>
            </li>
          </Link> */}
          {/* <Link to="/news">
            {" "}
            <li className="nav-item">
              <a className="nav-link" href="#">
                Sample Project
              </a>
            </li>
          </Link> */}
          {/* <Link to="/"> */}{" "}
          <li className="nav-item">
            <a className="nav-link" href="#">
              Contact Us
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/contact"> */}{" "}
          <li className="nav-item">
            <a className="nav-link" href="#">
              Client Testimonials
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/contact"> */}{" "}
          <li className="nav-item">
            <a className="nav-link" href="#">
              Article Posts
            </a>
          </li>
          {/* </Link> */}
          {/* <Link to="/contact"> */}{" "}
          <li className="nav-item">
            {/* <a
              className="nav-link"
              href="https://www.google.com/"
              target="_blank"
            >
              Signin / Signup
            </a> */}
            <a
              href="http://user.corevisualizers.com/user/login"
              target="_blank"
            >
              <button className="btn btn-outline-warning custom-mr">
                Signin
              </button>
            </a>
            <a
              href="http://user.corevisualizers.com/user/register"
              target="_blank"
            >
              <button className="btn btn-warning text-white">Signup</button>
            </a>
          </li>
          {/* </Link> */}
          {/* <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <a className="dropdown-item" href="#">
                Action
              </a>
              <a className="dropdown-item" href="#">
                Another action
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#">
                Something else here
              </a>
            </div>
          </li> */}
        </ul>
      </div>
    </nav>
    // </header>
    // <header>
    //   <div id='header'></div>
    //   <div className='logo'>
    //     <img
    //       src="./img/logowhite.png"
    //       className="img-fluid"
    //       alt="#"
    //     />
    //     <span className='callus'>
    //       Call Us: (+6221) 000 888 999
    //     </span>
    //   </div>
    //   <Wrapper open={isOn} shouldAnimate={shouldAnimate}>
    //     <Overlaybg open={isOn} shouldAnimate={shouldAnimate} onClick={closeHandler}/>
    //     <Container
    //       open={isOn}
    //       onClick={closeHandler}
    //       hasBackground={hasBackground}>
    //       <NavButton open={isOn} />
    //     </Container>
    //     <Body className='midwrpr' open={isOn} shouldAnimate={shouldAnimate}>
    //       <div className='conPage'>
    //           <Page className='mainBtn' variant="home" onClick={() => setLinkHandler("home")}>
    //               <LinkTag>Home</LinkTag>
    //           </Page>
    //           <Page className='mainBtn' variant="about" onClick={() => setLinkHandler("about")}>
    //               <LinkTag>About</LinkTag>
    //           </Page>
    //           <Page className='mainBtn' variant="work" onClick={() => setLinkHandler("work")}>
    //               <LinkTag>Projects</LinkTag>
    //           </Page>
    //           <Page className='mainBtn' variant="about" onClick={() => setLinkHandler("news")}>
    //               <LinkTag>News</LinkTag>
    //           </Page>
    //           <Page className='mainBtn' variant="about" onClick={() => setLinkHandler("contact")}>
    //               <LinkTag>Contact</LinkTag>
    //           </Page>
    //       </div>

    //       <div className='info'>
    //         <span>(+6221) 000 888 999</span>
    //         <span className='link'>support@homekins.com</span>
    //         <span>129 Park street, New York 10903</span>
    //       </div>

    //     </Body>
    //     <SocialContainer className='soc-icon' open={isOn}>
    //         <span>Follow us:</span>
    //         <span className='socicon'><i className="fa fa-facebook-f"></i></span>
    //         <span className='socicon'><i className="fa fa-linkedin"></i></span>
    //         <span className='socicon'><i className="fa fa-twitter"></i></span>
    //         <span className='socicon'><i className="fa  fa-instagram"></i></span>
    //     </SocialContainer>
    //   </Wrapper>
    // </header>
  );
};

export default withRouter(NavigationMenu);
